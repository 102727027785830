.checkbox-wrapper {
    display: flex;
    align-items: center;
    height: 30px;
  }
  .checkbox-title {
    width: 55px;
    min-width: 55px;
  }
  .checkbox-title-no-width {
    min-width: 55px;
  }