/********** CLASSES DOS DROPS DE FILTROS E ACOES **********/
/* Sempre verifique se a classe que você procura já       */
/* existe. Este arquivo ainda precisa ter suas classes    */
/* renomeadas de modo genérico e organizadas de modo      */
/* a facilitar a manutenção e compreensão.                */
/******* MATEUS FOLLETTO - 26/05/2020 - VIRTUEYES *********/
/******************** DROPDOWNS DE ACOES ********************/
.drop-acoes-container-filtro {
  position: absolute !important;
  z-index: 10000 !important;
  width: 68.25em !important;
  border-radius: 0.3125em !important;
  background-color: transparent !important;
  border: 0 !important;
  left: -629px;
}

.flex-filtros {
  display: flex;
  margin-left: 4px;
}

.flex-filtros-status {
  display: flex;
  margin-left: 4px;
  gap: 10px;
}

.flex-filtros-conexao {
  display: flex;
}

.flex-filtros-conexao-container {
  display: flex;
  gap: 35px;
}

.flex-filtros-consumo {
  display: flex;
}

.flex-filtros-consumo-portal {
  display: flex;
  margin-left: 18px;
}

.flex-filtros-pacotess {
  display: flex;
  margin-left: 158px;
}

.flex-filtros-trafego {
  display: flex;
  margin-left: 70px;
}

.drop-acoes-container {
  position: absolute !important;
  z-index: 10000 !important;
  width: 16.25em !important;
  border-radius: 0.3125em !important;
  background-color: transparent !important;
  border: 0 !important;
}

.drop-acoes-triangulo {
  width: 0 !important;
  height: 0 !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  border-bottom: 0.9375em !important;
  margin-left: 8% !important;
}

.drop-acoes-corpo-filtro {
  width: 88.75em !important;
  border-radius: 0.3125em !important;
  height: 0vh;
  margin-left: -212px;
}

.drop-acoes-corpo {
  width: 16.25em !important;
  max-height: 100vh !important;
  border-radius: 0.3125em !important;
  overflow: auto !important;
}

.drop-acoes-corpo-separador {
  width: 16.25em !important;
  max-height: 100vh !important;
  border-radius: 0.3125em !important;
  overflow: auto !important;
}

.drop-acoes-corpo-separador-portadora {
  width: 12em !important;
  max-height: 100vh !important;
  border-radius: 0.3125em !important;
  overflow: auto !important;
}

/*FILTRO NOVO*/

.drop-acoes-container-filtro-novo {
  position: absolute !important;
  right: -405px;
  z-index: 10000 !important;
  width: 70.25em !important;
  height: 20.25em !important;
  border-radius: 0.3125em !important;
  background-color: transparent !important;
  border: 0 !important;
}

.drop-acoes-corpo-filtro-novo {
  width: 100% !important;
  max-height: 100vh !important;
  border-radius: 0.3125em !important;
  overflow: auto !important;
  padding: 16px;
}

.filtro-novo-container {
  display: flex;
  flex-direction: column;
}

.filtro-novo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filtro-novo-titulo {
  display: flex;
  font-size: 24px;
  align-items: center;
  gap: 8px;
}

.filtro-novo-elipse-titulo {
  height: 14px;
}

.filtro-novo-limpar-btn {
  user-select: none;

  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.filtro-novo-btns {
  display: flex;
  gap: 8px;
}

.filtro-novo-action-buttons {
  display: flex;
  gap: 18px;
  align-items: center;
}

.filtro-green-text {
  color: #33cc99;
  font-size: 13px;
  font-weight: 700;
}

.filtro-limpar-icon {
  height: 11px;
}

.filtrar-novo-btn {
  cursor: pointer;

  user-select: none;

  color: white;
  font-weight: 700;
  font-size: 13px;
  background-color: #33cc99;
  border-radius: 24px;
  border-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 90px;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.filtrar-novo-btn:hover {
  background-color: #2ebd85;
}

.filtrar-novo-btn-disabled {
  cursor: pointer;

  user-select: none;

  color: #9d9c9c;
  font-weight: 700;
  font-size: 13px;
  background-color: #505352 !important;
  border-radius: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 90px;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.filtrar-novo-voltar-btn {
  cursor: pointer;

  user-select: none;

  color: #33cc99;
  font-weight: 700;
  font-size: 13px;
  background-color: transparent;
  border-radius: 24px;
  border: 1px solid #33cc99;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 90px;
}

.filtrar-novo-voltar-btn:hover {
  background-color: #33cc99;
  color: white;
}

.filtro-novo-body {
  display: flex;
  gap: 24px;
  padding: 16px;
  margin-top: 10px;
  border-radius: 8px;
}

.filtro-novo-body-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filtro-novo-radio-content {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 300px;
}

.radio-wrapper {
  display: flex;
  align-items: center;
  /* gap: 8px; */
  height: 25px;
}

.radiobutton-title {
  width: 90px;
  min-width: 50px;
}

.filtro-novo-body-titulo {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.filtro-novo-date-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.filtro-novo-checkbox-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 254px;
  gap: 0 29px;
}

.drop-acoes-divisora {
  margin-left: -10% !important;
  width: 101% !important;
  height: 0.0625em !important;
}
.drop-acoes-menu-estatico {
  margin-left: -2.5em !important;
  width: 16.25em !important;
  height: 1.875em !important;
  color: #fff !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  cursor: pointer;
}

.drop-acoes-menu {
  margin-left: -2.9em !important;
  width: 18em !important;
  height: 1.875em !important;
  color: #fff !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  cursor: pointer;
}

.drop-acoes-menu-label {
  margin-left: 1.25em !important;
  height: 1.875em !important;
  font-size: 1em !important;
  line-height: 1.75em !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}
.drop-acoes-menu-icone {
  float: right !important;
  margin-right: 1.5em !important;
  margin-top: 0.4375em !important;
}

.drop-acoes-submenu {
  margin-left: -2.5em !important;
  width: 16.25em !important;
  height: 1.875em !important;
  color: #fff !important;
  font-size: 1em !important;
  line-height: 1.75em !important;
  cursor: pointer;
}

.drop-acoes-submenu-label {
  margin-left: 2.1875em !important;
  height: 1.875em !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  font-weight: 700 !important;
}

.drop-acoes-lista-menu-emer {
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  height: 660px;
}

.drop-acoes-lista-menu {
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
}

.drop-acoes-lista-submenu {
  list-style-type: none;
}

/******************** DROPDOWNS DE FILTROS ********************/
.botao-limpar-filtro-habilitado {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
}

.botao-limpar-filtro-disable {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  border: 0.09375em solid #000 !important;
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
}

.botao-limpar-filtro-simcard {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  /* border: 0.09375em solid #36D896!important; */
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
  margin-right: -15.7em !important;
  margin-top: 0.8em !important;
  margin-left: -3.2em !important;
}

.botao-voltar-filtro-simcard {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 2.875em !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
  margin-top: 0.2em !important;
}

.botao-fechar-filtro {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  color: #36d896 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
  margin-right: -461px;
  margin-top: 13px;
}

.botao-mostrar-filtro-arquivo {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 0.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  /* color: #4F6275!important; */
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: right !important;
  margin-right: -330px;
  margin-top: 13px;
  width: 205px;
  margin-top: 13px;
}

.botao-aplicar-filtro-habilitado {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  /* background-color: #36D896!important; */
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
}

.botao-aplicar-filtro-disable {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  background-color: #505352 !important;
  color: #000 !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
  margin-right: -25.65em !important;
}

.botao-aplicar-filtro-simcard-habilitado {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  /* border: 0.09375em solid #36D896!important; */
  /* background-color: #36D896!important; */
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
  margin-right: -24em !important;
  margin-top: 1em !important;
}

.botao-aplicar-filtro-simcard-disable {
  font-family: RobotoBold !important;
  font-size: 0.875em !important;
  font-weight: 700 !important;
  line-height: 0.6em !important;
  height: 1.75em !important;
  min-width: 5.25em !important;
  padding-left: 1.875em !important;
  padding-right: 1.875em !important;
  background-color: #505352 !important;
  color: #000 !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  margin-left: 0.625em !important;
  float: right !important;
  margin-right: -24em !important;
  margin-top: 1em !important;
}

.botao-central {
  margin-left: -2.5em !important;
  display: block;
  text-align: center;
  width: 15.75em !important;
  padding: 0.75em;
}

.drop-filtros-divisora {
  margin-left: 0.8em !important;
  width: 14.125em !important;
  height: 0.0625em !important;
}

.drop-filtros-divisora-final {
  width: 16.75em !important;
  height: 0.0625em !important;
}

.drop-filtros-menu-label {
  font-family: RobotoBold;
  margin-left: 1.5em !important;
  height: 1.875em !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.drop-filtros-lista-submenu {
  /* text-align: center; */ /* REMOVIDO POR ESTAR DESALINHANDO SELECTS DENTRO DE FILTROS */
  list-style-type: none;
}

.drop-filtros-menu {
  width: 18em !important;
  height: 1.9em !important;
  color: #fff !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  cursor: pointer;
}
.dropdown-desativado-filtros {
  max-height: 0 !important;
  overflow: hidden !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}
.dropdown-ativado-filtros {
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}
.botao-central-filtros {
  display: block;
  text-align: center;
  width: 15.75em !important;
  padding: 0.75em;
}
button.item-dropdown-filtros-cabecalho-sistema-habilidado.btn.btn-default {
  font-family: RobotoBold;
  line-height: 1.1875em;
  font-size: 1em;
  width: 6.5625em;
  height: 2.1875em;
  border-radius: 3.125em;
  background-color: #36d896 !important;
  color: #000 !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}
button.item-dropdown-filtros-cabecalho-sistema-disable.btn.btn-default {
  font-family: RobotoBold;
  line-height: 1.1875em;
  font-size: 1em;
  width: 6.5625em;
  height: 2.1875em;
  border-radius: 3.125em;
  color: #000 !important;
}
button.item-dropdown-filtros-botao-limpar-habilitado.btn-default {
  font-family: RobotoBold;
  line-height: 1.1875em;
  font-size: 1em;
  width: 6.5625em;
  height: 2.1875em;
  background-color: transparent !important;
  border-radius: 3.125em;
  margin-right: 1em;
  border: 0.09375em solid #36d896;
  color: #36d896 !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}
button.item-dropdown-filtros-botao-limpar-disable.btn.btn-default {
  font-family: RobotoBold;
  line-height: 1.1875em;
  font-size: 1em;
  width: 6.5625em;
  height: 2.1875em;
  background-color: transparent !important;
  border-radius: 3.125em;
  margin-right: 1em;
  border: 0.09375em solid #000;
  color: #000 !important;
}
.container-select-filtro {
  margin-left: 0.9375em;
  margin-top: 0.625em;
  max-width: 14.25em !important;
}
.campo-select-filtro {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 100% !important;
}
.campo-select-filtro-2 {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 68% !important;
}

.campo-select-filtro-3 {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 63% !important;
}

.campo-select-filtro-5 {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 66% !important;
}

.campo-select-filtro-6 {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 66% !important;
}

.campo-select-filtro-b {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 78% !important;
}

.campo-select-contrato-filtro-b {
  display: -webkit-inline-box;
  height: 2em !important;
  width: 100% !important;
}

.menu-dropdown-filtro {
  margin-top: 0.625em !important;
  width: 15.625em !important;
  border-color: transparent !important;
}
.select-filtro-lista-menu {
  list-style-type: none !important;
}
.select-filtro-triangulo {
  position: absolute !important;
  z-index: 9000 !important;
  width: 0 !important;
  height: 0 !important;
  border-left: 0.9375em solid transparent !important;
  border-right: 0.9375em solid transparent !important;
  border-bottom: 0.9375em solid red !important;
  margin-top: -0.9375em !important;
  margin-left: 40% !important;
}
.select-filtro-corpo {
  max-height: 9.8125em !important;
  max-width: 14.25em !important;
  border-radius: 0.3125em !important;
  overflow-x: hidden !important;
  margin-top: 0.9375em !important;
}
.select-filtro-menu-estatico {
  margin-left: -2.5em !important;
  width: 14.375em;
  height: 1.875em !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  cursor: pointer;
}
.select-filtro-menu {
  margin-left: -2.5em !important;
  width: 14.375em;
  height: 1.875em !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  cursor: pointer;
}
.select-filtro-menu:hover {
  margin-left: -2.5em !important;
  width: 14.375em;
  height: 1.875em !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
}
.select-filtro-menu:active {
  margin-left: -2.5em !important;
  width: 14.375em;
  height: 1.875em !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
}
.select-filtro-menu-label {
  margin-left: 1.25em !important;
  float: left !important;
}
.select-filtro-divisora {
  margin-left: -1.25em !important;
  width: 12.125em !important;
  height: 0.0625em !important;
}
.drop-filtros-lista-submenu-simcard {
  text-align: initial;
}

.label-radio-filtros-conexao {
  /* margin-left: -3.6em !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-pacote {
  margin-left: 1.2em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.coluna-alterar-ciclo-vida-1-dias-3 {
  margin-left: 12px;
}

.label-radio-filtros-tempo {
  margin-left: 12px;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 108px !important;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-tempo-b {
  /* margin-left: -1.8em !important; */
  /* margin-left: -3.8em !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-pacote-b {
  margin-left: 0.2em;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-simcard {
  margin-left: -5.8em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-status {
  margin-left: -0.3em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-status-b {
  /* margin-left: -6.8em !important; */
  margin-top: 0.75em !important;
  /* margin-bottom: 0.65em !important; */
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-plano {
  /* margin-left: -9.8em !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-plano-b {
  /* margin-left: -2.8em !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-trafego {
  margin-left: -0.7em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  height: 1em;
  width: 100% !important;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  user-select: none;
}

.label-radio-filtros-trafego-2 {
  margin-left: 0.6em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-sms {
  margin-left: 1.2em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-sms-b {
  /* margin-left: -4.8em !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-sms-2 {
  /* margin-left: -20px !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-radio-filtros-tec {
  /* margin-left: -2.2em !important; */
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  height: 1em;
  width: 100% !important;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  user-select: none;
}

/* Customize the label (the container) */
.label-radio-filtros-2 {
  margin-left: -0.1em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  height: 1em;
  width: 100% !important;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  user-select: none;
}

.label-radio-filtros-extra {
  /* margin-left: -2.5em !important; */
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
}

.label-radio-filtros-extra-b {
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
}

.label-radio-filtros-extra-c {
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* margin-left: -2.4em !important; */
}

.label-radio-filtros {
  margin-left: 3.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 2em !important;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 12em !important;
}

/* Customize the label (the container) */
.label-checkbox-filtros-operadoras-2 {
  /* margin-left: -0.5em !important; */
  /* margin-bottom: 0.65em !important; */
  margin-top: 0.75em !important;
  display: inline-block;
  position: relative;
  padding-left: 2em !important;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 11em !important;
}

.label-checkbox-filtros-ope {
  margin-left: -0.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 2em !important;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 12em !important;
}

.label-checkbox-filtros-operadoras {
  margin-left: 3.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 2em !important;
  cursor: pointer;
  height: 1em;
  width: 100% !important; /*14.625em;*/
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 12em !important;
}

/* Hide the browser's default radio button */
.label-checkbox-filtros-operadoras input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-checkbox-filtros-operadoras-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-filtros-operadoras {
  position: absolute;
  bottom: 0.0625em;
  left: 0;
  height: 1.1em;
  width: 1.1em;
}

.checkbox-filtros-operadoras-2 {
  position: absolute;
  bottom: 0.0625em;
  left: 0;
  height: 1.1em;
  width: 1.1em;
  border: solid;
  border-width: 1px;
}

.label-checkbox-filtros-operadoras:hover input ~ .checkbox-filtros-operadoras {
  background-color: #36d896;
}

/* .label-checkbox-filtros-operadoras-2:hover input ~ .checkbox-filtros-operadoras {
    background-color: #36D896;
} */

/*COR DO FUNDO DEPOIS QUE O CHECKBOX É MARCADO (FOI COLOCADO NO GLOBAL POIS NAO PODE SER TRANSPARANTE )*/
/* .label-checkbox-filtros-operadoras input:checked ~ .checkbox-filtros-operadoras {
    background-color: transparent!important;
} */

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkbox-filtros-operadoras:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-filtros-operadoras-2:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-checkbox-filtros-operadoras
  input:checked
  ~ .checkbox-filtros-operadoras:after {
  display: block;
}

.label-checkbox-filtros-operadoras-2
  input:checked
  ~ .checkbox-filtros-operadoras-2:after {
  display: block;
}

/* COR DO INDICADOR DO CHECKBOX */
.label-checkbox-filtros-operadoras .checkbox-filtros-operadoras:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #36d896;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label-checkbox-filtros-operadoras-2 .checkbox-filtros-operadoras-2:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label-radio-filtros-automacao {
  margin-left: 3.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.65em !important;
  display: inline-block;
  position: relative;
  padding-left: 2em !important;
  cursor: pointer;
  height: 1em;
  width: 70% !important;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.label-radio-filtros input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-extra input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-extra-b input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-extra-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-tec input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-simcard input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-plano input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-plano-b input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-status input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-conexao input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-pacote input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-tempo input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-tempo-b input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-pacote-b input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-trafego input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-trafego-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-sms input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-sms-b input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-sms-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-status input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label-radio-filtros-status-b input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */

.checkbox-filtros {
  position: absolute;
  bottom: 0.0625em;
  left: 0;
  height: 0.875em;
  width: 0.875em;
  background-color: #252827;
  border-radius: 50%;
}

.checkbox-filtros-b {
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 14px;
  width: 14px;
  border-color: #4f6275 !important;
  border-radius: 50%;
  border: 1px solid #ddd;
}

/* On mouse-over, add a grey background color */
.label-radio-filtros:hover input ~ .checkbox-filtros {
  background-color: #36d896;
}

.label-radio-filtros-2:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-extra:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-extra-b:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-extra-c:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-tec:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

/* .label-radio-filtros-2:hover input ~ .checkbox-filtros {
    background-color: #36D896;
} */

.label-radio-filtros-simcard:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-plano:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-plano-b:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-status:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-status-b:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-conexao:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-pacote:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-pacote-tempo:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-pacote-tempo-b:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-pacote-b:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-trafego:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-trafego-2:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-sms:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-sms-b:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

.label-radio-filtros-sms-2:hover input ~ .checkbox-filtros-b {
  background-color: #36d896;
}

/* When the radio button is checked, add a blue background */
.label-radio-filtros input:checked ~ .checkbox-filtros {
  background-color: transparent !important;
}

.label-radio-filtros-2 input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-extra input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-extra-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-extra-c input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-tec input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

/* .label-radio-filtros-2 input:checked ~ .checkbox-filtros {
    background-color: transparent!important;
} */

.label-radio-filtros-simcard input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-plano input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-plano-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-status input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-status-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-conexao input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-pacote input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-tempo input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-tempo-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-pacote-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-tempo input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-tempo-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-trafego input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-trafego-2 input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-sms input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-sms-b input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

.label-radio-filtros-sms-2 input:checked ~ .checkbox-filtros-b {
  background-color: transparent !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkbox-filtros:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-filtros-b:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-radio-filtros input:checked ~ .checkbox-filtros:after {
  display: block;
}

.label-radio-filtros-2 input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-extra input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-extra-b input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-extra-c input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-tec input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-2 input:checked ~ .checkbox-filtros:after {
  display: block;
}

.label-radio-filtros-simcard input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-plano input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-plano-b input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-conexao input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-pacote input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-pacote-b input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-tempo input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-tempo-b input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-trafego input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-trafego-2 input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-sms input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-sms-b input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-sms-2 input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-status input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

.label-radio-filtros-status-b input:checked ~ .checkbox-filtros-b:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.label-radio-filtros .checkbox-filtros:after {
  top: 0.15625em;
  left: 0.15625em;
  width: 0.5625em;
  height: 0.5625em;
  border-radius: 50%;
  /* background: #36D896; */
}

.label-radio-filtros-2 .checkbox-filtros-b:after {
  top: 0.1em;
  left: 0.1em;
  width: 0.499em;
  height: 0.5625em;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-extra .checkbox-filtros-b:after {
  top: 0.1em;
  left: 0.1em;
  width: 0.499em;
  height: 0.5625em;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-extra-b .checkbox-filtros-b:after {
  top: 0.1em;
  left: 0.1em;
  width: 0.499em;
  height: 0.5625em;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-extra-c .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-tec .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-plano .checkbox-filtros-b:after {
  top: 0.1em;
  left: 0.1em;
  width: 0.499em;
  height: 0.5625em;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-plano-b .checkbox-filtros-b:after {
  top: 0.1em;
  left: 0.1em;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-simcard .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-conexao .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-pacote .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-tempo .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-tempo-b .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-pacote-b .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-trafego .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-trafego-2 .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-sms .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 0.499em;
  height: 0.5625em;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-sms-b .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-sms-2 .checkbox-filtros-b:after {
  top: 1px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-status .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-radio-filtros-status-b .checkbox-filtros-b:after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 97%;
  background: #36d896;
}

.label-texto-valor-filtros {
  cursor: pointer;
  height: 1em;
  font-family: RobotoRegular;
  font-size: 0.875em;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  user-select: none;
}

.filtros-texto-cliente {
  padding: 0.5em !important;
  width: 13em !important;
  height: 2em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-left: 1px;
  margin-bottom: 1em;
}

.filtros-iccid {
  padding: 0.3em !important;
  width: 12em !important;
  height: 1.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
}

.filtros-iccid-valor {
  padding: 0.3em !important;
  width: 12em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -50px;
  margin-top: -11px;
}

.filtros-iccid-valor-min {
  padding: 0.3em !important;
  width: 12em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-left: -50px;
}

.filtros-iccid-valor-min-b {
  padding: 0.3em !important;
  width: 11.3em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0.125em 3em 3em 0.125em !important;
  box-shadow: none !important;
  margin-left: -26px;
}

.filtros-iccid-valor-max-b {
  padding: 0.3em !important;
  width: 11.3em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0.125em 3em 3em 0.125em !important;
  box-shadow: none !important;
  margin-left: -26px;
  margin-top: -17px;
}

.filtros-iccid-valor-min-2 {
  padding: 0.3em !important;
  width: 3em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -37px;
  margin-top: -12px;
}

.filtros-iccid-valor-min-2-b {
  padding: 0.3em !important;
  width: 12em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 2em 3.125em !important;
  box-shadow: none !important;
  margin-left: -37px;
  margin-top: -12px;
}

.filtros-iccid-valor-min-2-b,
.filtros-iccid-valor-max {
  border-radius: 50px !important;
}

.filtros-iccid-valor-max-2 {
  padding: 0.3em !important;
  width: 3em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -37px;
  margin-top: -12px;
}
.filtros-iccid-valor-max-2-b {
  padding: 0.3em !important;
  width: 3em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -37px;
  margin-top: -17px;
}
.filtros-cifrao {
  padding: 0.3em !important;
  width: 2em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -49px;
  position: absolute;
}
.filtros-cifrao-b {
  padding: 0.3em !important;
  width: 2em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -49px;
  position: absolute;
  margin-top: -17px;
}
.filtros-cifrao-saldo {
  padding: 0.3em !important;
  width: 2em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -49px;
  position: absolute;
  margin-top: -22px;
}
.filtros-cifrao-saldo-b {
  padding: 0.3em !important;
  width: 2em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-left: -253px;
  position: absolute;
  margin-top: 19px;
}
.filtros-dias {
  padding: 0.3em !important;
  width: 10em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 48.125em 383em 225em 110em !important;
  box-shadow: none !important;
  margin-left: 50px;
  position: absolute;
  margin-top: -12px;
}
.filtros-dias-b {
  padding: 0.3em !important;
  width: 10em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 48.125em 383em 225em 110em !important;
  box-shadow: none !important;
  margin-left: 4px;
  position: absolute;
  margin-top: -17px;
}

.filtros-dias,
.filtros-dias-b {
  border-radius: 0 50px 50px 0 !important;
  width: 9.3em !important;
}

.filtros-mb {
  padding: 0.3em !important;
  width: 2em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 48.125em 383em 225em 110em !important;
  box-shadow: none !important;
  margin-left: 109px;
  position: absolute;
  margin-top: -1px;
}
.filtros-mb-b {
  padding: 0.3em !important;
  width: 2em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 48.125em 383em 225em 110em !important;
  box-shadow: none !important;
  margin-left: 77px;
  position: absolute;
  margin-top: -1px;
}
.filtros-iccid-valor-max {
  padding: 0.3em !important;
  width: 12em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-left: -37px;
  margin-top: -17px;
}

.filtros-iccid-valor-max-2 {
  padding: 0.3em !important;
  width: 12em !important;
  height: 2.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-left: -50px;
  margin-top: -17px;
}

.filtros-cliente-final {
  padding: 0.3em !important;
  width: 13em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: -8px;
  margin-left: 106px;
}

.filtros-texto-valor-mb-filtro {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
}

.filtros-texto-valor-mb-filtro-conexoes {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
}

.filtros-texto-valor-mb-filtro-pacote {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
  margin-left: 65px;
}

.filtros-texto-valor-mb-filtro-pacote-portal {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
  margin-left: 36px;
}

.filtros-texto-valor-mb-filtro-pacote-2 {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
  margin-left: 25px;
}

.filtros-texto-valor-mb-filtro-tempo {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
  /* margin-left: -79px; */
}

.filtros-texto-valor-mb-filtro-tempo-2 {
  padding: 0.3em !important;
  width: 6.125em !important;
  height: 2.3em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 0px;
  /* margin-left: -70px; */
}

.filtros-texto-valor-mb-filtro-saldo {
  padding: 0.3em !important;
  width: 12.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  margin-top: -22px;
  margin-left: -51px;
}

.filtros-texto-valor-mb-filtro-saldo-2 {
  padding: 0.3em !important;
  width: 12.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  margin-top: -22px;
  margin-left: -12px;
}

.filtros-texto-valor-mb-filtro-saldo-min {
  padding: 0.3em !important;
  width: 12.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: -22px;
  margin-left: -46px;
}

.filtros-texto-valor-mb-filtro-saldo-min-b {
  padding: 0.3em !important;
  width: 10.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0.125em 3em 3em 0.125em !important;
  box-shadow: none !important;
  margin-top: -22px;
  margin-left: -23px;
}

.filtros-texto-valor-mb-filtro-saldo-b {
  padding: 0.3em !important;
  width: 12.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 20px;
  margin-left: -260px;
}
.filtros-texto-valor-mb-filtro-saldo-c {
  padding: 0.3em !important;
  width: 10.525em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0.125em 3em 3em 0.125em !important;
  margin-top: -22px;
  margin-left: -25px;
}

.filtros-texto-valor-mb-filtro-saldo-d {
  padding: 0.3em !important;
  width: 10.4em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0.125em 3em 3em 0.125em !important;
  box-shadow: none !important;
  margin-top: 20px;
  margin-left: -227px;
}

.filtros-texto-valor-mb-filtro-iccid {
  width: 13.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: 20px;
  margin-left: -123px;
}
.filtros-texto-valor-mb-filtro-iccid-c {
  width: 13.125em !important;
  height: 2.5em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 3em 3em 3.125em !important;
  box-shadow: none !important;
  margin-top: -22px;
  margin-left: -16px;
}

.filtros-texto-valor-mb {
  /* input com label */
  padding: 0.3em !important;
  width: 2.7em !important;
  height: 1.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
}

.filtros-texto-valor-mb-sem-label {
  /* input sem label */
  padding: 0.3em !important;
  height: 1.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  box-shadow: none !important;

  width: 5.5em !important;
  border-radius: 3.125em !important;
}

.filtros-texto-valor-mb-texto {
  padding: 0.3em !important;
  width: 4.9em !important;
  height: 1.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em !important;
  box-shadow: none !important;
  text-align: center !important;
}

.filtros-texto-valor-mb-esquerda {
  padding: 0.3em !important;
  width: 2.7em !important;
  height: 1.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
}

.filtros-texto-valor-real {
  padding: 0.3em !important;
  width: 2.7em !important;
  height: 1.55em !important;
  line-height: 1em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
}

.filtros-texto-linha-margin-pacotes {
  margin-top: 0.3em !important;
  margin-bottom: 0.4em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.filtros-texto-linha-margin {
  margin-top: 0.3em !important;
  margin-bottom: 0.4em !important;
  margin-left: 2em !important;
}

.filtros-texto-linha-margin-conexoes {
  margin-top: 0.3em !important;
  margin-bottom: 0.4em !important;
  margin-left: 0 !important;
}

.filtros-texto-linha-margin-conexoes > .col-sm-6 {
  padding: 0;
}

.filtros-texto-linha-margin-portal {
  margin-top: 0.3em !important;
  margin-bottom: 0.4em !important;
}

.filtros-texto-linha-margin-2 {
  margin-top: 0.3em !important;
  margin-bottom: 0.4em !important;
  margin-left: -67px !important;
}

.filtros-label-mb {
  text-align: center !important;
  width: 9.125em !important;
  height: 1.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  /* background-color: #252827 !important; */
  /* color: #E0E0E0 !important; */
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
}

.filtros-label-mb-valor {
  text-align: center !important;
  width: 1.125em !important;
  height: 2.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  /* background-color: #252827 !important; */
  /* color: #E0E0E0 !important; */
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
}

.filtros-label-mb-valor-min {
  text-align: center !important;
  width: 1.125em !important;
  height: 2.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  /* background-color: #252827 !important; */
  /* color: #E0E0E0 !important; */
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
}

.filtros-label-mb {
  text-align: center !important;
  width: 2.125em !important;
  height: 1.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  /* background-color: #252827 !important; */
  /* color: #E0E0E0 !important; */
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
  padding-right: 0.5em !important;
}

.filtros-label-mb-pacote {
  text-align: center !important;
  width: 9.125em !important;
  height: 1.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  /* background-color: #252827 !important; */
  /* color: #E0E0E0 !important; */
  vertical-align: middle !important;
  border-radius: 0em 3.125em 3.125em 0em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
}

.filtros-label-mb-esquerda {
  text-align: center !important;
  width: 2.125em !important;
  height: 1.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
  padding-right: 0.5em !important;
}
.filtros-label-real {
  text-align: center !important;
  width: 2.125em !important;
  height: 1.55em !important;
  line-height: 1.5125em !important;
  font-size: 0.875em !important;
  font-family: RobotoRegular !important;
  border: 0em !important;
  background-color: #252827 !important;
  color: #e0e0e0 !important;
  vertical-align: middle !important;
  border-radius: 3.125em 0em 0em 3.125em !important;
  box-shadow: none !important;
  margin-bottom: 0em !important;
  padding-right: 0.5em !important;
}

.conteudo-calendario-filtro {
  margin-bottom: 0.8em;
  margin-left: 1.8em;
  margin-right: 1.8em;
}

.conteudo-calendario-filtro-simcard {
  margin-bottom: 0.8em;
  margin-left: 10px;
}

.conteudo-calendario-filtro-simcard .campo-texto-input-calendario {
  width: 11.9em !important;
}

.conteudo-calendario-filtro .campo-texto-input-calendario {
  width: 11.9em !important;
}

.conteudo-calendario-filtro-simcard button {
  line-height: 1em;
  font-size: 0.875em;
  font-family: Roboto;
  font-weight: bold;
  vertical-align: middle;
}

.conteudo-calendario-filtro button {
  line-height: 1em;
  font-size: 0.875em;
  font-family: Roboto;
  font-weight: bold;
  vertical-align: middle;
}

.label-selecione-calendario-filtros {
  margin-left: 1.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.75em !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 1em;
  width: 14.625em;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.campo-select-modal-label-solicitacao-cliente {
  float: left !important;
  line-height: 1.125em !important;
  text-align: start;
  text-overflow: clip;
  white-space: pre;
  overflow-x: hidden;
  width: 86%;
  line-height: 1em;
  margin-left: 0.625em;
  margin-top: 0.5em;
}

.campo-select-modal-icone-solicitacao-cliente {
  float: right !important;
  margin-top: 0.5em;
  height: 0.8125em;
}

.campo-select-radio-escondido-solicitacao-cliente {
  background-color: transparent !important;
  height: 0em !important;
  width: 0% !important;
}

.campo-select-btn-solicitacao-cliente {
  width: 15.9375em !important;
  height: 2em !important;
  color: #fff !important;
  font-size: 0.875em !important;
  line-height: 1.75em !important;
  cursor: pointer;
  background-color: violet;
  border-radius: 3.125em;
}

.conteudo-calendario-filtro-solicitacao-cliente {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  margin-left: 1.8em;
  margin-right: 1.8em;
}
.conteudo-calendario-filtro-solicitacao-cliente {
  width: 11.9em !important;
}
.conteudo-calendario-filtro-solicitacao-cliente button {
  line-height: 1em;
  font-size: 0.875em;
  font-family: Roboto;
  font-weight: bold;
  vertical-align: middle;
}

.select-autocomplete-servico-adicional {
  min-width: 182px !important;
  width: 300px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.select-autocomplete-servico-adicional > .form-group {
  max-width: 255px;
}

.select-autocomplete-servico-adicional-portal {
  width: 275px !important;
  margin-left: 50px !important;
  margin-right: 120px !important;
}

/****************RETIRADO DO GLOBAL NECESSARIO REVISAO********************/
/* .drop-acoes-container{
    position: absolute!important;
    z-index: 5000;
    width: 15.75em!important;
    border-radius: 0.3125em !important;
    background-color: transparent!important;
    border: 0 !important;
} */
.drop-acoes-corpo-filtro {
  width: 88.75em !important;
  border-radius: 0.3125em !important;
}

.drop-acoes-menu-icone-ativado {
  float: right !important;
  margin-right: 1.25em !important;
  margin-top: 0.4375em !important;
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.drop-acoes-menu-icone-desativado {
  float: right !important;
  margin-right: 1.25em !important;
  margin-top: 0.4375em !important;
  transform: rotate(-90deg);
  -webkit-transition: 0.5s ease !important;
  -moz-transition: 0.5s ease !important;
  -o-transition: 0.5s ease !important;
  transition: 0.5s ease !important;
}

.posicionador-menu-filtros {
  position: absolute !important;
  z-index: 10000 !important;
}

.label-campo-select {
  width: 13em !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
}

.usuario-dados-corpo-linha {
  margin-top: 1em !important;
}

.btn-filtro-arquivo {
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.25em !important;
  width: 8.4em !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: left !important;
  display: flex !important;
  align-items: center;
  margin-bottom: 10px !important;
}

.btn-filtro-arquivo-template {
  font-family: RobotoBold !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  line-height: 1.1875em !important;
  height: 2.25em !important;
  width: 7.75em !important;
  border: 0.09375em solid #787b7a !important;
  background-color: #787b7a !important;
  box-shadow: none !important;
  border-radius: 3.125em !important;
  float: left !important;
  display: flex !important;
  align-items: center;
  margin-bottom: 10px !important;
  text-decoration: none !important;
}

.img-filtro-arquivo-template {
  width: 30px;
  height: 20px;
  margin-left: 0.575em;
}

.texto-arquivo-template {
  margin-left: 0.575em;
}
.texto-arquivo-template-2 {
  margin-left: 0em;
}

.input-filtro-arquivo {
  position: absolute;
  z-index: -1;
}

.img-filtro-arquivo {
  width: 30px;
  height: 30px;
  margin-left: 0.875em;
}

.modal-filtro-arquivo {
  padding: 0.5em 2em 2em 2em !important;
  border-radius: 0.625em 0.625em 0.625em 0.625em !important;
  width: 36em !important;
  z-index: 300 !important;
  /* margin-left: -30em !important; */
}

.modal-filtro-arquivo-simcard {
  padding: 0.5em 2em 2em 2em !important;
  border-radius: 0.625em 0.625em 0.625em 0.625em !important;
  width: 36em !important;
  z-index: 300 !important;
  margin-left: 90em !important;
}

.label-alerta-filtro-arquivo {
  font-family: RobotoRegular !important;
  font-size: 0.875em !important;
  line-height: 1.5em !important;
  margin: 10px 0 0 0;
}

.componente-modal-filtro-arquivo-container {
  /* transform: translate(75vw, 10%)!important; */
  position: absolute !important;
  /* transform: translate(-50%, -0%)!important; */
  /* margin-top: 4.8125em!important; */
  margin-top: 6.8125em !important;
  margin-left: 45vw;
  /* margin-left: 61.5%; */
}

.componente-modal-filtro-arquivo-container-virtueyes-clientes {
  position: absolute !important;
  margin-top: 6.8125em !important;
  margin-left: -30em !important;
}

.campo-texto-select-tamanho {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  text-align: left !important;
}

.campo-texto-select-tamanho-2 {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  text-align: left !important;
  margin-top: 6px;
}

.label-texto-conta-filtros {
  margin-left: 1.5em !important;
  margin-top: 0.75em !important;
  margin-bottom: 0.75em !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 1em;
  width: 14.625em;
  font-family: RobotoRegular;
  font-size: 0.875em !important;
  line-height: 1em;
  text-align: initial !important;
  -webkit-user-select: none;
  user-select: none;
}

.select-generico-filtro {
  width: 80% !important;
  margin-left: 10% !important;
  margin-top: 1em !important;
}

.textoTipoPessoa {
  font-size: 16px;
  margin-bottom: 8px;
}

.linha {
  width: 840px;
  border: 0.5px solid #b1b1b1;
  margin-top: 20px;
}

.containerConteudoLinha {
  display: flex;
  flex-direction: row;
  height: 80px;
  margin-top: 20px;
  justify-content: space-between;
}
.menuItem:first-of-type {
  margin-top: -7px;
}

.menuItem {
  padding: 0px !important;
  height: 40px;
  font-size: 8px;
}

.checkboxClass {
  margin: 0 10.5px 0 18.5px;
  padding: 0;
}

.containerConteudoFormulario {
  height: 55vh;
  overflow-y: hidden;
}

.containerConteudoLinha2 {
  display: flex;
  flex-direction: row;
  height: 80px;
  margin-top: 20px;
  justify-content: flex-start;
  gap: 24px;
}

.label-text {
  font-size: 20px !important;
}

.icone-alerta-filtro-arquivo {
  width: 20px;
  height: 20px;
}

.drop-acoes-container-filtro-novo {
  position: absolute !important;
  right: -405px;
  z-index: 10000 !important;
  width: 80.25em !important;
  height: 20.25em !important;
  border-radius: 0.3125em !important;
  background-color: transparent !important;
  border: 0 !important;
}

.filtro-novo-checkbox-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 340px;
  gap: 0 29px;
}
